import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom"; // React Router integration
import { Link as ScrollLink } from "react-scroll";
import { HeaderButton } from "./Buttons";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const MENU_ITEMS = [
    { name: "Hosting", href: "#" },
    { name: "Development", href: "#" },
    { name: "Solutions", href: null, hasDropdown: true }, // Dropdown for Solutions
    { name: "Why Us?", href: "#why-choose-us" },
  ];

  const SOLUTIONS_ITEMS = [{ name: "CDN", href: "/solutions/cdn" }];

  const isScrollLink = (href: string | null) => {
    return href ? href.startsWith("#") : false;
  };

  return (
    <header className="sticky top-0 z-50 flex justify-between items-center px-6 lg:px-20 py-6 bg-dark text-light shadow-md">
      {/* Logo */}
      <Link
        to="/"
        className="flex items-center text-white font-medium text-2xl"
      >
        <img
          src={"/logo256.png"}
          alt="sCloud"
          className="w-12 h-12 inline mr-2"
        />
        sCloud
      </Link>

      {/* Hamburger Menu for Mobile */}
      <button
        className="lg:hidden text-white focus:outline-none"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d={
              isMenuOpen
                ? "M6 18L18 6M6 6l12 12" // Close Icon
                : "M4 6h16M4 12h16M4 18h16" // Hamburger Icon
            }
          />
        </svg>
      </button>

      {/* Navigation */}
      <AnimatePresence>
        {isMenuOpen || window.innerWidth > 1024 ? (
          <motion.nav
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-100%", opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="lg:flex gap-10 absolute lg:static top-16 left-0 w-full lg:w-auto bg-dark lg:bg-transparent px-6 lg:px-0 py-4 lg:py-0"
          >
            {MENU_ITEMS.map((item, index) => (
              <div key={index} className="relative group">
                {item.hasDropdown ? (
                  <>
                    {/* Dropdown Trigger */}
                    <button
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      className="block lg:inline hover:text-primary focus:outline-none"
                    >
                      {item.name}
                    </button>
                    {/* Dropdown Menu */}
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          className="absolute lg:top-full lg:left-0 mt-2 bg-[#1E293B] shadow-lg rounded-lg p-4 w-48"
                        >
                          {SOLUTIONS_ITEMS.map((solution, idx) => (
                            <Link
                              key={idx}
                              to={solution.href}
                              className="block text-light hover:text-primary py-2 px-3 rounded-md transition"
                            >
                              {solution.name}
                            </Link>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                ) : (
                  <>
                    {isScrollLink(item.href) ? (
                      <ScrollLink
                        to={item.href!.slice(1)}
                        smooth={true}
                        duration={500}
                        className="block lg:inline hover:text-primary focus:outline-none"
                      >
                        {item.name}
                      </ScrollLink>
                    ) : (
                      <Link
                        to={item.href!}
                        className="block lg:inline hover:text-primary focus:outline-none"
                      >
                        {item.name}
                      </Link>
                    )}
                  </>
                )}
              </div>
            ))}
          </motion.nav>
        ) : null}
      </AnimatePresence>

      {/* Dashboard Button */}
      <div className="hidden lg:block">
        <HeaderButton
          onClick={() => {
            alert("You clicked the button!");
          }}
        >
          Dashboard
        </HeaderButton>
      </div>
    </header>
  );
};

export default Header;
