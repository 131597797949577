import React from "react";

const Checkmark: React.FC<{
  size?: number;
}> = ({ size = 48 }) => {
  return (
    <div className="relative flex items-center justify-center">
      {/* Back Gradient Circle */}
      <div
        className="absolute w-full h-full rounded-full bg-gradient-to-r from-primary to-secondary translate-x-4 translate-y-3"
        style={{ width: size / 1.5, height: size / 1.5 }}
      ></div>

      {/* Glassmorphism Circle */}
      <div
        className="relative flex items-center justify-center rounded-full bg-opacity-30 bg-dark backdrop-blur-md border border-light shadow-md border-opacity-20"
        style={{ width: size, height: size }}
      >
        {/* Checkmark SVG */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="white"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    </div>
  );
};

export default Checkmark;
