import { motion } from "framer-motion";
import WhyChooseUs from "../../components/WhyChooseUs";
import CDNPlans from "../../components/CDNPlans";
import CDNHero from "../../components/CDNHero";

const CDNPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <CDNHero />
      <CDNPlans />
      <WhyChooseUs />
    </motion.div>
  );
};

export default CDNPage;
