import Checkmark from "./Checkmark";

const WhyChooseUs = () => {
  const stats = [
    {
      title: "99.9% uptime guaranteed",
      description:
        "We put a lot of time into the miniaturization and renewal of technology to maintain our SLA.",
      icon: "✓", // Replace with SVG if needed
    },
    {
      title: "Swift support 24x7x36",
      description:
        "Our team monitors infrastructure 24x7x36 for swift support and on-the-spot issue resolution without wait times.",
      icon: "✓", // Replace with SVG if needed
    },
    {
      title: "Quick setup",
      description:
        "With quick and painless setup options, you can perform any task in your client area with just one click.",
      icon: "✓", // Replace with SVG if needed
    },
    {
      title: "Facility & Security",
      description:
        "We strive to keep servers online while offering key security measures to safeguard your information.",
      icon: "✓", // Replace with SVG if needed
    },
  ];

  return (
    <section className="bg-dark py-16 px-6" id="why-choose-us">
      {/* Section Heading */}
      <div className="max-w-5xl mx-auto text-center mb-12">
        <h2 className="text-3xl lg:text-4xl font-bold text-white">
          Why choose us?
        </h2>
        <p className="mt-4 text-light">
          sCloud stands out for its professional approach in providing hosting,
          development, and IT services while continuously striving to improve
          the solutions we offer.
        </p>
      </div>

      {/* Features Section 1x2*/}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 px-6 lg:px-20 mx-auto mb-12">
        {/* Feature 1 */}
        <div className="bg-[#1E293B] rounded-lg p-6 shadow hover:shadow-lg transition duration-300 hover:scale-105">
          <h3 className="text-xl font-semibold text-white">Our Dashboard</h3>
          <p className="text-light mt-2 max-w-lg">
            We know just how important a dashboard is in the modern world. All
            of our services come with a built-in dashboard to help you manage
            your services on the go with ease.
          </p>
          <img
            src="https://placehold.co/400x200"
            alt="Data privacy"
            className="w-full h-60 object-cover rounded-md mt-4"
          />
        </div>

        {/* Feature 2 & 3*/}
        <div className="grid grid-cols-1 gap-6">
          {/* Feature 2 */}
          <div className="bg-[#1E293B] rounded-lg px-6 shadow hover:shadow-lg transition duration-300 hover:scale-105 flex items-center">
            <div className="flex flex-col items-start justify-center w-full">
              <h3 className="text-xl font-semibold text-white">
                Flexibility of content
              </h3>
              <p className="text-light mt-2 max-w-lg">
                Your site, your content. We offer total flexible hosting with
                lightning-fast speeds. We've got you covered.
              </p>
            </div>
            <img
              src="/images/cube.png"
              alt="Data privacy"
              className="h-32 object-contain"
            />
          </div>

          {/* Feature 3 */}
          <div className="bg-[#1E293B] rounded-lg px-6 py-4 shadow hover:shadow-lg transition duration-300 hover:scale-105 flex items-center relative overflow-hidden">
            <div className="flex flex-col items-start justify-center w-full">
              <h3 className="text-xl font-semibold text-white">
                High performance servers
              </h3>
              <p className="text-light mt-2 max-w-lg">
                Web Hosting, VPS and Dedicated Servers are offering scalable
                affordability with a high level of security and performance
                thanks to PulseHeberg.
              </p>
            </div>
            <div className="absolute right-0 top-0 h-full w-1/3">
              {/* Image Overlay */}
              <img
                src="/images/squares.png"
                alt="Flexibility of content"
                className="h-full w-full object-cover "
              />
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-6 lg:px-20 mx-auto">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="bg-[#1E293B] rounded-lg p-6 text-left shadow hover:shadow-lg transition duration-300 hover:scale-105 flex flex-col items-start"
          >
            <Checkmark />
            <h4 className="text-lg font-semibold text-white mt-4">
              {stat.title}
            </h4>
            <p className="text-light mt-2">{stat.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
