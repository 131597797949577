import React from "react";

export const HeroButton: React.FC<{
  children: React.ReactNode;
  fullWidth?: boolean;
  onClick: () => void;
}> = ({ children, fullWidth, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="relative flex items-center justify-between p-1 text-white font-medium rounded-full bg-gradient-to-r from-primary to-secondary hover:scale-105 transition-transform duration-300 ease-in-out"
      style={{ width: fullWidth ? "100%" : "auto" }}
    >
      <span className="px-4 py-2 text-white cursor-pointer">{children}</span>
      <span className="ml-2 flex items-center justify-center w-10 h-10 rounded-full bg-white text-primary cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </span>
    </button>
  );
};

export const HeaderButton: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
}> = ({ children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="relative flex items-center justify-center p-1 text-white font-medium rounded-full bg-gradient-to-r from-primary to-secondary hover:scale-105 transition-transform duration-300 ease-in-out"
    >
      <span className="px-4 py-2 text-white">{children}</span>
    </button>
  );
};
