const Features = () => {
  const features = [
    {
      title: "Reliable Hosting",
      description:
        "High-speed SSD servers and self-healing infrastructure for ultimate reliability.",
    },
    {
      title: "Web Development",
      description: "Custom web solutions tailored to your business needs.",
    },
    {
      title: "Game Development",
      description:
        "Powerful tools and expertise to create immersive gaming experiences.",
    },
    {
      title: "PC Building & Repair",
      description:
        "Expert local services for fixing or building your ideal PC setup.",
    },
    {
      title: "Scalable Solutions",
      description:
        "Flexible resources and operating systems to meet your unique requirements.",
    },
  ];

  return (
    <section className="bg-dark py-16">
      <div className="mx-auto px-6 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
        {features.map((feature, index) => (
          <div
            key={index}
            className="p-6 bg-[#1E293B] rounded-lg shadow hover:shadow-lg transition duration-300 hover:scale-105"
          >
            <h3 className="text-lg font-semibold text-white mb-2">
              {feature.title}
            </h3>
            <p className="text-light">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
